import React, { useState } from 'react';
import logo from './images/l.jpeg'
import './Footer.css'

function Footer() {
  const [email, setEmail] = useState('');
  const handleSubscribe = (e) => {
    e.preventDefault(); 
    if (email) {
      
      console.log(`Subscribed with email: ${email}`);
      setEmail('');
      alert('Thank you for subscribing!');
    } else {
     
      alert('Please enter a valid email.');
    }
  };

  return (
    <div>
      <div className="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Contact Us</h5>
              <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Kasturi Engineering Works, M- 60 & 61 M.I.D.C. Area, Jalgaon – 425 003</p>
              <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+919422207846 // +919422791696</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Our Products</h5>
              <a className="btn btn-link" href="Product">Plastics Moulds</a>
              <a className="btn btn-link" href="blow">Blow Moulds</a>
              <a className="btn btn-link" href="pvc">PVC Fitting Moulds</a>
              <a className="btn btn-link" href="agri">Agri Fitting Moulds</a>
              <a className="btn btn-link" href="electric">Electrical Fitting Mould</a>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <a className="btn btn-link" href="about">About Us</a>
              <a className="btn btn-link" href="contact">Contact Us</a>
              <a className="btn btn-link" href="service">Our Services</a>
              <a className="btn btn-link" href="contact">Home</a>
              <a className="btn btn-link" href="product">Product</a>
            </div>
            <div className="col-lg-3 col-md-6">
              <a className="logo">
                <img src={logo} alt="Company Logo" style={{ maxWidth: '300px' , height:'180px',marginLeft:'-13 vh' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4" style={{ background: '#000000',marginTop:'-10vh' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a className="border-bottom" href="https://www.shreedigimarketing.in/">KASTURI ENGINEERING WORKS</a>, All Rights Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              <a href='https://shreedigimarketing.in/'>Designed By Shree Digital Marketing Agency</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import React from 'react';
import './Tech.css';
import cn from './images/cn.jpeg';
import c from './images/c.jpg';
import toolImage1 from './images/about.jpg';
import toolImage2 from './images/about.jpg';
import toolImage3 from './images/about.jpg';
import toolImage4 from './images/about.jpg';

function Tech() {
  return (
    <div>

      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Technical Information</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
              <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">Technical Information</li>
            </ol>
          </nav>
        </div>
      </div>

   
      <div className="container my-5">
        <h2 className="text-center mb-5">Tooling for Injection Molds</h2>

       
        <section>
          <h3>Overview:</h3>
          <p>
            <strong>Parts Design</strong> (client may provide completed 3D files):  
            A part design that has been completed and verified is the starting point for the tooling process. Before tooling, we can optionally optimize your design for Design for Asian Manufacturing (TM) to reduce costs and ensure it will work well with Asian processes.
          </p>
          <p>
            <strong>Parts Prototyping</strong> (if necessary):  
            If you want to confirm your part design with an actual machined piece that you can see and feel, we can make prototypes using Computer Numerical Control (CNC) techniques.
          </p>
          <img src={cn} alt="Prototyping" className="img-fluid rounded mb-5" />
        </section>

        {/* Tool Design Section */}
        <section>
          <h3>Tool Design</h3>
          <p>
            At this stage, the tool is designed, using the information from your completed Tooling Information Worksheet.
          </p>
          <div className="row mb-5">
            <div className="col-md-4">
              <img src={toolImage1} alt="Tool Design" className="img-fluid rounded" />
            </div>
            <div className="col-md-4">
              <img src={toolImage2} alt="Tool Design" className="img-fluid rounded" />
            </div>
            <div className="col-md-4">
              <img src={toolImage3} alt="Tool Design" className="img-fluid rounded" />
            </div>
          </div>
        </section>

        {/* Tool Check and Mold Flow Analysis Section */}
        <section>
          <h3>Tool Check and Mold Flow Analysis</h3>
          <p>The tool design is validated for correctness.</p>
         
        </section>

        {/* Tool Fabrication Section */}
        <section>
          <h3>Tool Fabrication</h3>
          <p>
            The tool is precisely fabricated according to the validated design, including the use of Computerized Numerical Control (CNC) and Electrical Discharge Machining (EDM) technologies.
          </p>
          
        </section>

        {/* First Shots to Client Section */}
        <section>
          <h3>First Shots to Client</h3>
          <p>
            Once the injection molds are made, the first test shot samples will be sent out soon after via international courier. Once sent, these usually take around 2-5 days to arrive.
          </p>
        </section>

        {/* Tool Modifications and Texturing Section */}
        <section>
          <h3>Tool Modifications and Texturing</h3>
          <p>
            At this stage, We complete the tool and make any required changes to bring the tool into the approved spec. Changes made to bring the tool to spec (specifically excluding design changes, which are always charged for) are made free of charge by We. Texturing also takes place at this stage, which is normally the last stage before mass production.
          </p>
        </section>

        {/* Payments Section */}
        <section>
          <h3>Payments</h3>
          <p>Our normal payment schedule is 40% initial deposit, 30% on First Shot, and 30% on approval.</p>
        </section>

        {/* Timing Section */}
        <section>
          <h3>Timing</h3>
          <p>
            For tooling, it usually takes 5 to 6 weeks from receipt of deposit funds for molds to be made, assuming that 3D files are correct. If you have a special urgent request, we can reduce the lead time to 3 weeks (additional fees apply). "T1" samples are the first stage test shot samples produced based on your 3D files. Depending on the original 3D design and complexity of the plastic parts, it takes usually one to two weeks, sometimes longer, to complete the test shot stage.
          </p>
        </section>

        {/* Freight Forwarding Section */}
        <section>
          <h3>Freight Forwarding</h3>
          <p>
            Shipment times depend on which shipment method is used (i.e. DHL, UPS, FedEx, TNT, air cargo or sea shipment). Our prices are quoted FOB China or FOB Taiwan. If you have appointed a freight forwarder, we can use your account and existing agreements for a nominal fee. If you do not have a specific forwarder, we can provide names of forwarders we have worked with in the past.
          </p>
        </section>

        {/* How to Buy Section */}
        <section>
          <h3>How Can I Buy?</h3>
          <p>
            KASTURI MOULDS is your ideal destination when it comes to creating custom injection molds with only an idea and no idea where to start. We provide a complete solution that includes product design and injection molding! KASTURI MOULDS is a top injection mold company in India that can create custom injection molds to turn your product ideas into business success.
          </p>
          <ul>
            <li>If you have design: Send us your product or mold design.</li>
            <li>If you have a sample: Send it to us, and we'll handle the customs clearance and tax.</li>
            <li>If you have an idea for an innovative product: Come to us! We can sign a confidentiality agreement before you share your ideas.</li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default Tech;
